import {
    LISTE_ARTICLES_SUCCESS,
    LISTE_ARTICLES_FAIL,
    LISTE_ARTICLES_LOADING,
    LISTE_ARTICLES_ADD_MODAL,
    LISTE_ARTICLES_ADD_SUCCESS,
    LISTE_ARTICLES_ADDCHANNEL_MODAL,
    LISTE_ARTICLES_2_SUCCESS,
    LISTE_ARTICLES_WITHCHANNEL_SUCCESS,
    LISTE_ARTICLES_IMPORTCSV_MODAL,
    LISTE_ARTICLES_IMPORTCSV_LOADING, LISTE_ARTICLES_DETAILS_ITEM_MODAL, LISTE_ARTICLES_UPDATE_ARTICLECHANNEL_SUCCESS, LISTE_ARTICLES_UPDATE_ARTICLECHANNEL_LOADING
} from "../actions/types";

const initialState = {
    articles: [],
    articleschannel: [],
    articles_2: [],
    totalRows: 0,
    totalPages: 0,
    article: null,
    articleDetails: null,
    showAddModal: false,
    showAddChannelModal: false,
    showImportArticlesModal: false,
    showDetailsArticleModal: false,
    loading: false,
    loadingImportCSV: false,
    loadingUpdateArticleChannelIndex: -1,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LISTE_ARTICLES_SUCCESS:
            return {
                ...state,
                articles: payload.articles,
                totalRows: payload.totalRows,
                totalPages: payload.totalPages,
                loading: false
            };
        case LISTE_ARTICLES_WITHCHANNEL_SUCCESS:
            return {
                ...state,
                articleschannel: payload.articleschannel,
            };
        case LISTE_ARTICLES_2_SUCCESS:
            return {
                ...state,
                articles_2: payload.articles,
                totalRows: payload.totalRows,
                totalPages: payload.totalPages,
                loading: false
            };
        case LISTE_ARTICLES_ADD_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case LISTE_ARTICLES_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case LISTE_ARTICLES_IMPORTCSV_LOADING:
            return {
                ...state,
                loadingImportCSV: payload.loading
            };

        case LISTE_ARTICLES_ADD_MODAL:
            return {
                ...state,
                showAddModal: payload.show
            };

        case LISTE_ARTICLES_ADDCHANNEL_MODAL:
            return {
                ...state,
                showAddChannelModal: payload.show,
                article: payload.article
            };

        case LISTE_ARTICLES_DETAILS_ITEM_MODAL:
            return {
                ...state,
                showDetailsArticleModal: payload.show,
                articleDetails: payload.article
            };

        case LISTE_ARTICLES_UPDATE_ARTICLECHANNEL_LOADING:
            return {
                ...state,
                loadingUpdateArticleChannelIndex: payload.index,
            };

        case LISTE_ARTICLES_UPDATE_ARTICLECHANNEL_SUCCESS:
            return {
                ...state,
                data: payload.data,
            };

        case LISTE_ARTICLES_IMPORTCSV_MODAL:
            return {
                ...state,
                showImportArticlesModal: payload.show,
            };

        default:
            return state;
    }
}
